@use '@angular/material' as mat;
@import './app-colors';

@include mat.core();

$block-app-yellow: (
  100: $block-yellow,
  contrast: (
    100: #3e3d40,
  )
);

$block-app-grey: (
  100: $block-grey,
  contrast: (
    100: #ffed00,
  )
);

$block-app-red: (
  100: $block-red,
  contrast: (
    100: #ffed00,
  )
);

$block-app-primary: mat.define-palette($block-app-yellow, 100, 100, 100);
$block-app-accent: mat.define-palette($block-app-grey, 100, 100, 100);
$block-app-warn: mat.define-palette($block-app-red, 100, 100, 100);

$block-app-theme: mat.define-light-theme((
  color: (
    primary: $block-app-primary,
    accent: $block-app-accent,
    warn: $block-app-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($block-app-theme);

.mat-card {
  background-color: $block-white;
  margin-bottom: 1.25rem;
}

.error-box {
  background-color: $block-red;
  color: $block-white;
  font-weight: 500;
  margin: .3rem 0;
  padding: .6rem;
}
