// You can add global styles to this file, and also import other style files
@import './app-colors';

@font-face {
  font-display: swap;
  font-family: 'unisans';
  font-weight: 400;
  src: url('/assets/fonts/uni-sans/Fontfabric-UniSansRegular.otf');
}

@font-face {
  font-display: swap;
  font-family: 'unisans';
  font-weight: 500;
  src: url('/assets/fonts/uni-sans/Fontfabric-UniSansBold.otf');
}

* {
  font-family: 'unisans';
}

body {
  margin: 0;
  padding: 0;
}

// sass-lint:disable-block no-vendor-prefixes, attribute-quotes, no-qualifying-elements, force-pseudo-nesting, force-attribute-nesting
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.not-found-list-element {
  align-self: center;
  padding: 1.25rem 0;
}

.feedback-dialog {
  max-width: 80vw;
  min-width: 80vw;
}

snack-bar-container {
  &.snack-bar {
    color: $block-white;

    &--warn {
      background-color: $block-red;
    }

    &--success {
      background-color: $block-green;
    }
}
}

